<template>
  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap" rel="stylesheet"> 
  
  <CarListing v-if="hasId" :id="id" :location_options="location_options" />
  <CarSearch v-else :location_options="location_options" />
</template>

<script>
import CarSearch from './components/CarSearch.vue'
import CarListing from './components/CarListing.vue'

export default {
  name: 'App',
  components: {
    CarSearch, CarListing
  },
  data() {
    return {
      hasId: false,
      id: 0,
      location_options: {
        101: 'Main',
        110: 'Adelaide',
        406: 'Adelaide',
        111: 'Brisbane Airport',
        408: 'Brisbane Airport',
        112: 'Brisbane',
        113: 'Cairns',
        407: 'Cairns',
        123: 'Melbourne',
        124: 'Melbourne Airport',
        409: 'Melbourne Airport',
        209: 'Newcastle',
        410: 'Newcastle',
        127: 'Gold Coast',
        125: 'Launceston',
        126: 'Hobart',
        120: 'Sunshine Coast',
        411: 'Sunshine Coast',
        128: 'Sydney',
        325: 'Sydney',
        431: 'Perth',
      },
    };
  },
  mounted() {
    this.$store.dispatch('fetchVehicleStateLabels');
    let regex = /cars\/(?<id>[0-9]+)-/i;

    const found = document.URL.match(regex);

    if (found && found.groups && found.groups.id) {
      this.id = found.groups.id;
      this.hasId = true;
    }
  },
}
</script>

<style>
body {
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 16px;
}
@media (max-width: 600px) {
  #app {
    margin-top: 6px;
  }
}
#app * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

#app .swiper-button-next {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 56px;
  width: 44px;
  height: 44px;
  min-width: auto;
  min-height: auto;
}
#app .swiper-button-prev {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 56px;
  width: 44px;
  height: 44px;
  min-width: auto;
  min-height: auto;
}
#app .swiper-wrapper {
  padding: 0 !important;
  /* transition-duration: 0.25s !important; */
}
.component-fade-enter-active,
.component-fade-leave-active {
  opacity: 1 !important;
  transition: opacity 0.15s ease !important;
}

.component-fade-enter-from,
.component-fade-leave-to {
  opacity: 0 !important; 
}
</style>
